import { Box, Center, Image, Text } from '@chakra-ui/react';

import Slider from 'components/general/Slider';

const settings = {
  autoPlay: true,
  autoPlaySpeed: 1000,
  infinite: true,
  dots: true,
  arrows: false,
  slidesToShow: 1,
  responsive: [
    { breakpoint: 1200, settings: { slidesToShow: 1 } },
    { breakpoint: 900, settings: { slidesToShow: 1 } },
    { breakpoint: 650, settings: { slidesToShow: 1 } },
  ],
};

const TestimonialsSlider = ({ testimonials }: { testimonials: any[] }) => (
  <Box pos="relative">
    <Image src="/img/landing-pages/quote01.png" alt="quote" pos="absolute" />
    <Slider
      settings={settings}
      style={{
        width: '100%',
        maxWidth: '800px',
        margin: 'auto',
        zIndex: 1,
        padding: '0 0 10px !important',
      }}
    >
      {testimonials.map((p, i) => (
        <Box
          key={i}
          maxW="90%"
          m={['10px', '20px', '30px']}
          my={0}
          height="200px"
          pos="relative"
        >
          <Text textAlign="center" fontSize="lg">
            {p.description}
          </Text>
          <Center>
            <Image
              src={p.imageUrl}
              alt={p.name}
              objectFit="cover"
              borderRadius="full"
              boxSize="6rem"
            />
          </Center>
        </Box>
      ))}
    </Slider>
    <Image
      src="/img/landing-pages/quote02.png"
      alt="quote"
      pos="absolute"
      bottom={0}
      right={0}
    />
  </Box>
);

export default TestimonialsSlider;
