import { Heading, Box, HStack, Icon, Divider, Link } from '@chakra-ui/react';
import { FaTiktok, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';

import NoWrap from 'components/landing-pages/NoWrap';
import { sansFonts } from '@inspire/ui/static';

const media = [
  { icon: FaTiktok, url: 'https://www.tiktok.com/@article_un' },
  { icon: FaInstagram, url: 'https://www.instagram.com/article_un/' },
  {
    icon: FaLinkedin,
    url: 'https://fr.linkedin.com/school/association-article-1/',
  },
  { icon: FaYoutube, url: 'https://www.youtube.com/c/Article1' },
];
const SocialChannels = () => (
  <Box>
    <Heading as="h3" textAlign="center" mt={0} mb={5} fontFamily={sansFonts}>
      <NoWrap>Suivez-nous !</NoWrap>
    </Heading>
    <HStack
      h={['3rem', '6rem']}
      spacing={{ base: '5', md: '10' }}
      justifyContent="center"
      alignItems="center"
      divider={
        <Divider
          orientation="vertical"
          borderColor="black"
          transform="rotate(8deg)"
        />
      }
    >
      {media.map((m) => (
        <Box key={m.url} w="4rem" h={['3rem', '3.5rem']}>
          <Link href={m.url} isExternal>
            <Icon as={m.icon} w="100%" h="100%" />
          </Link>
        </Box>
      ))}
    </HStack>
  </Box>
);
export default SocialChannels;
